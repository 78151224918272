import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Container from '../components/Layout/Container';

const StyledSection = styled.section`
  text-align: center;
`;

export default function PageNotFound() {
  return (
    <StyledSection>
      <Container>
        <img src="/error_404.png" alt="Error 404" width="350" />
        <h2>Página no encontrada</h2>
        <p> Si lo deseas, puedes volver a la <Link to="/"> página principal</Link></p>
      </Container>
    </StyledSection>
  );
}
